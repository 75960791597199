input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.img-fluid {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.class-name {
  font-size: 14px !important;
  font-weight: 500;
  color: #000 !important;
}

.class-name span {
  color: rgba(0, 0, 0, 0.6) !important;
  font-weight: 600 !important;
}

.student-profile--wrapper {
  display: flex;
  align-items: center;
}

.student-profile--inner {
  display: flex;
  align-items: center;
  -ms-flex: 0 0 25%;
  flex: 0 0 75%;
  max-width: 75%;
}

.student-avatar {
  width: 100px;
  background: red;
  color: #fff;
  height: 100px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  margin-right: 20px;
}

.student-content {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.student-content h4 {
  margin: 0;
  font-size: 1.25rem;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.student-content p {
  margin: 0;
  color: #7a7a7a;
  font-size: 15px;
}

.student-class {
  -ms-flex: 0 0 75%;
  flex: 0 0 25%;
  max-width: 25%;
  font-size: 0.9rem;
  color: #7a7a7a;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
}

.student-class span {
  color: #000;
}

.align-items-center {
  align-items: center;
}

.text-right {
  text-align: right;
}

.mb-5 {
  margin-bottom: 1.5rem;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col-md-8 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-md-4 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

p.text-align {
  display: flex;
  margin-bottom: 6px;
}

p.text-align .class-name {
  margin-left: 10px;
  display: flex;
}

.icon-wrap {
  color: #7a7a7a;
  margin-right: 5px;
}

.icon-wrap svg {
  width: 20px;
  height: 20px;
}
.profile-data {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
}

.data-key {
  flex: 0 0 50%;
  width: 50%;
  background: #f5f5f5;
  padding: 5px 5px;
  border-radius: 0.25rem;
  margin-right: 10px;
  height: 31px;
}

.data-value {
  flex: 0 0 50%;
  width: 50%;
  color: #7a7a7a;
  background: #f5f5f5;
  padding: 5px 5px;
  border-radius: 0.25rem;
  height: 31px;
}

.button-wrapper {
  width: 100%;
  height: 274px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-1 {
  flex: 1 1 0 !important;
}

.w-50 {
  width: 50% !important;
}
.w-49 {
  width: 49% !important;
}

@media screen and (max-width: 500px) {
  .col-12 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.boxbdr {
  /* border-color: #000000;
  border-width: 10;*/
  border-radius: 10px;
  border: 1px solid rgb(173, 173, 173);
  padding: 20px;
}

#sideMenuSearchBar {
  padding: 7px 14px !important;
}

.addObservationContainer{
  flex: 1;
}